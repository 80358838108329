<template>
    <v-form>
        <v-card>
            <v-card-title>
                <span class="headline">Invalidate password</span>
            </v-card-title>

            <v-card-text>
                <p>
                    Are you sure you want to invalidate this user's password?
                    In order to be able to log in again they will need to be re-sent a signup link.
                </p>
            </v-card-text>

            <v-card-actions>
                <div class="flex-grow-1" />
                <v-btn color="blue darken-1" text @click="$emit('cancel')">
                    Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="invalidatePassword">
                    Invalidate Password
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        value: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            valid: true,
            item: {},
            passwordRules: [
                v => !!v || 'Password is required'
            ]
        }
    },

    methods: {
        async invalidatePassword() {
            let userId = this.value;

            try {
                await axios.post(
                    '/api/Users/InvalidatePassword/',
                    JSON.stringify(userId),
                    { headers: { "Content-Type": "application/json" } })
                this.$emit('save');
            }
            catch (error) {
                this.handleError(error);
            }
        },
    },
};
</script>
